export enum JobDescriptionActions {
  JOB_Path = "job",
  JOBS_Path = "jobs",
  STATUS_PATH = "status",
  UPLOAD_PATH = "upload",
  QUESTIONS_PATH = "questions",
  JOB_TITLES_PATH = "job_titles",
}

export enum CandidateActions {

  CANDIDATES_PATH = "candidates",
  CANDIDATE_PATH = "candidate",
  STATUS_PATH = "status",
  RESUME_PATH = "resume",
  RESUMES_PATH = "resumes",
  CALLS_PATH = "calls",
  CALL_PATH = "call",
  UPCOMING_PATH = "upcoming",
}

export enum CandidateStatus {

}